import React from "react";
import { Link } from "react-router-dom";
import CustomModal, { ModalInput } from "./Modal";

const DispatchTable = ({
  columns,
  data,
  complete,
  dispatchNote,
  setDispatchNote,
}) => {
  const [showCompleteModal, setShowCompleteModal] = React.useState(false);
  const [selectedOrderIds, setSelectedOrderIds] = React.useState(null);

  const handleComplete = (orderIds) => {
    if (setDispatchNote) {
      setSelectedOrderIds(orderIds);
      setShowCompleteModal(true);
    } else {
      complete(orderIds);
    }
  };

  return (
    <div className="w-full overflow-x-auto ">
      <table className="table-auto border-separate border-spacing-4">
        <thead>
          <tr>
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
              Date
            </th>
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
              City
            </th>
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
              Location
            </th>
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
              Items
            </th>
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
              Quantity
            </th>
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
              PhoneNumber
            </th>{" "}
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
              Customer Name
            </th>{" "}
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
              Challan
            </th>{" "}
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-yellow-400">
              Image
            </th>{" "}
            <th className="border whitespace-nowrap border-black px-2 py-1 bg-red-600">
              <Link
                to={`/pdf/staff/dispatch`}
                target="_blank"
                className="text-white"
              >
                PDF
              </Link>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            const orders = item?.orders?.length;
            return (
              <React.Fragment key={index}>
                {item?.orders?.map((order, index) => {
                  return (
                    <tr key={index}>
                      <td className="border border-black px-2 py-1">
                        {new Date(order?.updatedAt).toLocaleDateString() +
                          " " +
                          new Date(order?.updatedAt).toLocaleTimeString()}
                      </td>
                      <td className="border border-black px-2 py-1">
                        {order?.group}
                      </td>
                      <td className="border border-black px-2 py-1">
                        {order?.customer?.location}
                      </td>
                      <td className="border border-black px-2 py-1">
                        {order?.product?.productName}
                      </td>
                      <td className="border border-black px-2 py-1">
                        {order?.quantity}
                      </td>
                      {index === 0 && (
                        <td
                          rowSpan={orders}
                          className={`px-2 py-1 whitespace-nowrap border border-black`}
                        >
                          {item._id}
                        </td>
                      )}
                      <td className="border border-black px-2 py-1">
                        {order?.customer?.userName}
                      </td>
                      <td className="border border-black px-2 py-1">
                        <Challan item={item} order={order} />
                      </td>
                      {index === 0 && (
                        <td
                          rowSpan={orders}
                          className={`px-2 py-1 whitespace-nowrap border border-black`}
                        >
                          <Images images={item?.images} />
                        </td>
                      )}
                      {index === 0 && complete && (
                        <td
                          rowSpan={orders}
                          className={`px-2 py-1 whitespace-nowrap border border-black`}
                        >
                          <div className="flex gap-3 flex-col">
                            <button
                              className="bg-green-500  px-2 py-1 "
                              onClick={() => handleComplete(item?.orderIds)}
                            >
                              Complete
                            </button>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </React.Fragment>
            );
          })}
          {data?.length === 0 && (
            <tr>
              <td className="border border-black px-2 py-1">No Data</td>
            </tr>
          )}
        </tbody>
      </table>

      <CustomModal
        title="Complete Dispatch"
        open={showCompleteModal}
        setOpen={setShowCompleteModal}
        onClick={() => {
          complete(selectedOrderIds);
          setShowCompleteModal(false);
        }}
      >
        <ModalInput
          title="Note"
          value={dispatchNote}
          onChange={(e) => setDispatchNote(e.target.value)}
        />
      </CustomModal>
    </div>
  );
};

export default DispatchTable;

const Challan = ({ item, order }) => {
  const [open, setOpen] = React.useState(false);

  const [weight, setWeight] = React.useState(0);

  return (
    <div>
      <button
        onClick={() => setOpen(true)}
        className="bg-green-500 text-white px-2 py-1"
      >
        Challan
      </button>
      <CustomModal
        title={"Challan"}
        open={open}
        setOpen={setOpen}
        className="flex justify-center items-center"
        onClick={() => {
          window.open(
            `/admin/dispatch/challan/${item._id}/${order?._id}/${weight}`,
            "_blank"
          );
        }}
      >
        <ModalInput
          type="number"
          title={"Weight"}
          onChange={(e) => setWeight(e.target.value)}
        />
      </CustomModal>
    </div>
  );
};

const Images = ({ images }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div>
      <button
        onClick={() => setOpen(true)}
        className="bg-green-500 text-white px-2 py-1"
      >
        View
      </button>
      <CustomModal
        open={open}
        setOpen={setOpen}
        className="flex justify-center items-center"
        button={<></>}
      >
        <div className="grid grid-cols-2">
          {images?.map((item, index) => (
            <a key={index} href={item} target="_blank" rel="noreferrer">
              <img
                src={item}
                alt="s"
                className="h-80 w-full object-contain"
                key={item}
              />
            </a>
          ))}
        </div>
      </CustomModal>
    </div>
  );
};
