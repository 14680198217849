import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { dashboardProfit } from "../../utils/api";
import { formatDate } from "../../utils/formatData";
import Table from "../Table";
import EditPayment from "./EditPayment";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProfitAndPurchase = ({ dashboardCount, dash }) => {
  const [value, setValue] = React.useState(0);
  console.log(dash);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [payments, setPayments] = useState({});
  const [loading, setLoading] = useState(false);
  const getAllPaymentsHandler = async () => {
    setLoading(true);
    const [success, data] = await dashboardProfit();
    console.log(data);
    if (success) {
      setPayments(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllPaymentsHandler();
  }, []);
  return (
    <>
      {dashboardCount?.dataType === "Profit" && (
        <Box sx={{ width: "100%", marginTop: "20px" }}>
          <div className="flex gap-6">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label={`Payment (${payments?.paymentAmount})`}
                  {...a11yProps(0)}
                />

                <Tab
                  label={`Received (${payments?.receivedAmount})`}
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <div className="z-[30]  rounded-full  flex items-center justify-center">
              Balance (Without GST) :{payments?.balanceWithoutGst?.toFixed(2)}
            </div>
            <div className="z-[30]  rounded-full  flex items-center justify-center">
              Balance (With GST) :{payments?.balanceWithGst?.toFixed(2)}
            </div>
            <div className="z-[30]  rounded-full  flex items-center justify-center">
              Due Payment :{payments?.duePayment?.toFixed(2)}
            </div>
            <div className="z-[30]  rounded-full  flex items-center justify-center">
              Adjustment :{payments?.adjustment?.toFixed(2)}
            </div>
          </div>
          <CustomTabPanel value={value} index={0}>
            <Table
              data={
                payments?.payments?.length > 0
                  ? [...payments?.payments]?.reverse()
                  : []
              }
              columns={[
                {
                  title: "Date",
                  dataIndex: "date",
                  render: (record) => formatDate(record?.date),
                },
                {
                  title: "Customer Name",
                  render: (record) => record?.customer?.userName,
                },
                {
                  title: "description",
                  dataIndex: "description",
                },
                {
                  title: "Payment Type",
                  dataIndex: "paymentType",
                },
                {
                  title: "Amount",
                  dataIndex: "amount",
                  render: (record) => `₹ ${record.amount?.toFixed(2)}`,
                },
                {
                  title: "User",
                  dataIndex: "completedByName",
                },

                {
                  title: "Actions",
                  render: (record) => (
                    <EditPayment data={record} reload={getAllPaymentsHandler} />
                  ),
                },
                {
                  title: "PDF",
                  link: "/admin/pdf/profit",
                },
              ]}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Table
              data={
                payments?.received?.length > 0
                  ? [...payments?.received]?.reverse()
                  : []
              }
              columns={[
                {
                  title: "Date",
                  dataIndex: "date",
                  render: (record) => formatDate(record?.date),
                },
                {
                  title: "Customer Name",
                  render: (record) =>
                    record?.type === "PurchaseOrder"
                      ? record?.clients?.userName
                      : record?.customer?.userName,
                },
                {
                  title: "description",
                  dataIndex: "description",
                },
                {
                  title: "Payment Type",
                  dataIndex: "type",
                  render: (record) =>
                    record.type === "PurchaseOrder"
                      ? "PurchaseOrder"
                      : record.paymentType,
                },

                {
                  title: "Amount",
                  dataIndex: "amount",
                  render: (record) =>
                    `₹ ${
                      record.amount?.toFixed(2) ??
                      record?.totalAmount?.toFixed(2)
                    }`,
                },
                { title: "User", dataIndex: "completedByName" },
                {
                  title: "Actions",
                  render: (record) => (
                    <EditPayment data={record} reload={getAllPaymentsHandler} />
                  ),
                },
                {
                  title: "PDF",
                  link: "/admin/pdf/received",
                },
              ]}
            />
          </CustomTabPanel>
        </Box>
      )}
    </>
  );
};

export default ProfitAndPurchase;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
