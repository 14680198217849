import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ApiSearch from "../../components/ApiSearch";
import ClientTable from "../../components/ClientTable";
import Dash from "../../components/Dash";
import Layout from "../../components/Layout/Layout";
import Loading from "../../components/Loading";
import CustomModal, {
  ModalDatePicker,
  ModalInput,
  ModalSelect2,
} from "../../components/Modal";
import InvoicesModal from "../../components/modals/InvoicesModal";
import {
  getAllCustomers,
  getCustomersById,
  getInvoiceNo,
  submitInvoiceDetail,
} from "../../utils/api";
import useStore from "../../utils/store";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Customer = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const query = useQuery();
  const router = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [edit, setEdit] = useState(null);
  const startDate = useStore((state) => state.startDate);
  const endDate = useStore((state) => state.endDate);
  const [createInvoice, setCreateInvoice] = useState(null);
  const [invoiceNo, setInvoiceNo] = useState("");
  const [invoiceData, setInvoiceData] = useState(null);
  const [customerId, setCustomerId] = useState("");
  const [productList, setProductList] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [orderIds, setOrderIds] = useState(null);
  const [data, setData] = useState({});
  const [currentCustomer, setCurrentCustomer] = useState(null);
  var label;
  const getAllCustomerHandler = async () => {
    setLoading(true);
    const [success, data] = await getAllCustomers(startDate, endDate);
    if (success) {
      setCustomers(
        data.map((item) => ({
          ...item,
          name: `${item.userName} ${
            item?.group?.name !== undefined ? `(${item?.group?.name})` : ""
          }`,
        }))
      );
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getAllCustomerHandler();
  }, [startDate, endDate]);

  const getCustomersByIdHandler = async (id, name) => {
    setLoading(true);
    const [success, data] = await getCustomersById(id, startDate, endDate);
    if (success) {
      setCustomer({
        ...data,
        name,
      });
      setCustomerId(id);
      setCurrentCustomer(customers?.find((item) => item._id === id));
    }
    setLoading(false);
  };

  const invoiceHandler = async () => {
    setLoading(true);
    const [success, data] = await submitInvoiceDetail({
      ...invoiceData,
      invoiceNumberString: invoiceNo,
      customerId: customerId,
      orderIds: orderIds?.split(","),
    });

    if (success) {
      setCreateInvoice(false);
      toast.success("Invoice Created successfully");

      setOrderIds(null);
    }
    setLoading(false);
  };
  const printinvoiceHandler = async () => {
    setLoading(true);
    const [success, data] = await submitInvoiceDetail({
      ...invoiceData,
      invoiceNumberString: invoiceNo,
      customerId: customerId,
      orderIds: orderIds?.split(","),
    });
    console.log(data);
    if (success) {
      console.log(data);
      setCreateInvoice(false);
      toast.success("Invoice Created successfully");
      //blank page
      router(`/admin/invoice/${data._id}`);

      setOrderIds(null);
    }
    setLoading(false);
  };

  const invoiceNoHandler = async (customerId) => {
    setInvoiceLoading(true);
    const [success, data] = await getInvoiceNo({
      customerId: customerId,
    });
    if (success) {
      setInvoiceNo(data.invoiceNumberString);
      setProductList(data.products);
    }
    setInvoiceLoading(false);
  };

  useEffect(() => {
    if (query?.get("id")) {
      setData({
        cur: query?.get("id"),
      });
      const name = customers.find((item) => item._id === query?.get("id"));
      getCustomersByIdHandler(query?.get("id"), name?.name);
    }
  }, [query?.get("id")]);
  console.log(customer);

  return (
    <Layout>
      {loading && <Loading />}
      <div className="flex gap-3  flex-col md:flex-row w-full text-[90%]">
        <ApiSearch
          active={data?.cur}
          data={customers
            .filter((item) => item?.type === "Customer")
            .map((item) => ({
              ...item,
              userName: `${item.userName} ${
                item?.group?.name !== undefined ? `(${item?.group?.name})` : ""
              }`,
            }))}
          item={(item) => {
            getCustomersByIdHandler(item._id, item?.name || item?.userName);
            setData({
              cur: item._id,
            });
          }}
          customer
        />
        {customer && (
          <div className="flex flex-col gap-3 md:w-[calc(100%-18rem)] flex-1 ">
            <div className="flex gap-6  flex-wrap">
              <div className="flex  text-red-500 w-fit font-semibold  bg-yellow-400 px-3 py-2 border border-black">
                {customer?.name}
              </div>
              <div className="flex  text-black w-fit font-semibold  bg-secondary px-3 py-2 border border-black">
                Wagon : {customer?.wagon}
              </div>
              <div className="flex  text-black w-fit font-semibold  bg-secondary px-3 py-2 border border-black">
                Tag : {customer?.wagon * 580}
              </div>
              <div className="flex  text-black w-fit font-semibold  bg-secondary px-3 py-2 border border-black">
                Completed Tag : {customer?.orderTagAmount}
              </div>
              <div className="flex  text-black w-fit font-semibold  bg-secondary px-3 py-2 border border-black">
                Balance : {customer?.bal}
              </div>
              {currentCustomer?.phoneNumber && (
                <div className="flex  text-black w-fit font-semibold  bg-secondary px-3 py-2 border border-black">
                  PhoneNumber : {currentCustomer?.phoneNumber}
                </div>
              )}
              <InvoicesModal id={customerId} />
              <div
                className="flex  text-white w-fit font-semibold  bg-primary px-3 py-2 border border-black cursor-pointer"
                onClick={() => {
                  invoiceNoHandler(customerId);
                  setCreateInvoice(true);
                }}
              >
                Create Bill
              </div>
              <Link
                className="flex  text-white w-fit font-semibold  bg-primary px-3 py-2 border border-black cursor-pointer"
                to={`/pdf/ledger/customer/${customerId}`}
              >
                Ledger
              </Link>
            </div>
            <div className="flex  gap-6    flex-col md:flex-row">
              <Dash title={"Sale"} value={customer?.totalSales?.toFixed(2)} />
              <Dash
                title={"Payment Received"}
                value={customer?.totalPaymentRecived?.toFixed(2)}
              />
              <Dash
                title={"Balance"}
                value={customer?.totalBalance?.toFixed(2)}
              />
            </div>
            <ClientTable
              customers={customers}
              id={data?.cur}
              reload={() =>
                getCustomersByIdHandler(
                  data?.cur,
                  customers.find((item) => item._id === data?.curr)?.name
                )
              }
              edit={(data) => {
                setEdit(true);
                if (data.paymentType) {
                  setData({
                    _id: data._id,
                    customerId: data?.customer?._id,

                    description: data.description,
                    paymentType: data.paymentType,
                    amount: data.amount,
                  });
                } else {
                  setData({
                    _id: data._id,
                    customerId: data?.customer?._id,

                    description: data.description,
                    group: data.group,
                    quantity: data.quantity,
                    rate: data.rate,
                    gst: data.gst,
                    totalAmount: data.totalAmount,
                  });
                }
              }}
              data={[...(customer?.records ?? {})]?.reverse()}
              columns={[
                {
                  title: "Date",
                  dataIndex: "date",
                },
                {
                  title: "Customer",
                  dataIndex: "customer",
                  render: (item) => {
                    return item.customer.name;
                  },
                },
                {
                  title: "description",
                  dataIndex: "description",
                },
                {
                  title: "Items",
                  dataIndex: "group",
                },
                {
                  title: "Qnt.",
                  dataIndex: "quantity",
                },
                {
                  title: "Rate",
                  dataIndex: "rate",
                },
                {
                  title: "GST",
                  dataIndex: "gst",
                },
                {
                  title: "Total",
                  dataIndex: "totalAmount",
                },
              ]}
            />
          </div>
        )}
      </div>
      <CustomModal
        open={createInvoice}
        setOpen={() => {
          setCreateInvoice(false);
        }}
        button={
          <div className="flex gap-6">
            <Button
              variant="contained"
              color="primary"
              onClick={printinvoiceHandler}
            >
              {invoiceLoading ? "Loading..." : "Save and Print"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={invoiceHandler}
            >
              {invoiceLoading ? "Loading..." : "Save"}
            </Button>
          </div>
        }
        loading={invoiceLoading}
      >
        <ModalInput
          title={"Invoice No"}
          onChange={(e) => {
            setInvoiceNo(e.target.value);
          }}
          value={invoiceNo}
        />
        <ModalDatePicker
          title={"Invoice Date"}
          onChange={(e) => {
            setInvoiceDate(e.target.value);
            var result = e.target.value + "T13:40:31.520Z";
            setInvoiceData({
              ...invoiceData,
              invoiceDate: result,
            });
          }}
          value={invoiceDate}
        />
        <ModalInput
          title={"Note"}
          onChange={(e) => {
            setInvoiceData({
              ...invoiceData,
              note: e.target.value,
            });
          }}
          value={invoiceData?.note || ""}
        />
        <ModalSelect2
          title={"Product"}
          value={label}
          options={productList}
          onChange={(e) => {
            let index = e.nativeEvent.target.selectedIndex;
            label = e.nativeEvent.target[index].text;
            setOrderIds(e.target.value);
            // orderIds?.split(",").join(`","`);
          }}
        />
      </CustomModal>
    </Layout>
  );
};

export default Customer;
