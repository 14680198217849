import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { updatePayment } from "../../utils/api";
import CustomModal, { ModalInput } from "../Modal";
import CustomSelect from "../Select";

const EditPayment = ({ data, reload }) => {
  const [edit, setEdit] = useState(false);
  const [data2, setData2] = useState({});

  useEffect(() => {
    setData2(data);
  }, [data, edit]);
  return (
    <>
      <Button onClick={() => setEdit(true)} variant="contained">
        Edit
      </Button>
      <CustomModal
        open={edit}
        setOpen={() => {
          setEdit(false);
          setData2({});
        }}
        onClick={async () => {
          if (data2?.paymentType) {
            const [success, _] = await updatePayment(data2?._id, {
              ...data2,
              amount: parseFloat(data2.amount),
              date: data2.date,
            });
            if (success) {
              toast.success("Payment Updated");
              reload();
              // getCustomersByIdHandler(
              //   data.customerId,
              //   customers.find((item) => item._id === data.customerId).name
              // );
              setEdit(false);
              setData2({});
            }
            return;
          }
        }}
      >
        <div className="flex flex-col gap-3">
          {/* <div className="flex justify-between gap-2">
            <div className="  border-black ">Customer</div>
            <CustomSelect
              width={"256px"}
              onChange={(e) => {
                setData2({
                  ...data2,
                  customerId: e.target.value,
                });
              }}
              options={customers
                ?.filter((item) => item?.type === "Customer")
                ?.map((customer) => ({
                  value: customer._id,
                  label: customer.name,
                }))}
              value={data2.customerId || data2?.customer?._id}
            />
          </div> */}

          <div className="flex justify-between gap-2">
            <div className=" border-black ">Payment Type</div>

            <CustomSelect
              width={"256px"}
              onChange={(e) => {
                setData2({
                  ...data2,
                  paymentType: e.target.value,
                });
              }}
              options={[
                { value: "Received", label: "Received" },
                { value: "Payment", label: "Payment" },
              ]}
              value={data2.paymentType}
            />
          </div>

          <ModalInput
            title={"description"}
            value={data2.description}
            onChange={(e) => {
              setData2({
                ...data2,
                description: e.target.value,
              });
            }}
          />
          <ModalInput
            title={"date"}
            type="date"
            value={
              typeof data2?.date === "string" && data2?.date?.split("T")?.[0]
            }
            onChange={(e) => {
              setData2({
                ...data2,
                date: e.target.value,
              });
            }}
          />

          <ModalInput
            title={"amount"}
            value={data2.amount}
            type={"number"}
            onChange={(e) => {
              setData2({
                ...data2,
                amount: e.target.value,
              });
            }}
          />
        </div>
      </CustomModal>
    </>
  );
};

export default EditPayment;
